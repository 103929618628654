<template>
  <div class="checkout-main-page" >
    <checkout-header />
<!--    <dashboard />-->
    <dashboard-container />
    <history-modal />
  </div>
</template>

<script>
import { mapActions, mapMutations, mapState } from "vuex";
import {i18n} from "@/public/i18n";
import DashboardContainer from "@/entries/checkout/components/Dashboard/DashboardContainer.vue";

export default {
  name: "CheckoutMainPage",
  components: {
    DashboardContainer,
    CheckoutHeader: () => import(/* webpackChunkName: "entries/checkout/components/Header" */ '@/entries/checkout/components/Header.vue'),
    // Dashboard: () => import(/* webpackChunkName: "entries/checkout/components/Dashboard" */ '@/entries/checkout/components/Dashboard.vue'),
    HistoryModal: () => import(/* webpackChunkName: "/entries/checkout/components/Modal/HistoryModal" */ '@/entries/checkout/components/Modal/HistoryModal'),
  },
  // ордер https://checkout.onchainpay.xyz/d499cc26-b70f-4961-9070-96eea6ba4753?theme=light&lang=en
  computed: {
    ...mapState(['toggle', 'themeStatus']),

    background() {
      if (!this.toggle) return {'--background': '#E7F0FF'};
      return {'--background': '#ffffff'};
    },
  },
  // created() {
  // },
  mounted() {
    setInterval(() => {
      this.fetchOrder()
    }, 20 * 1000);

    this.updateWidth();
      this.toggleTheme();
    window.addEventListener('resize', this.updateWidth);
      const queryString = window.location.search
      const urlParams = new URLSearchParams(queryString);
      const urlTheme = urlParams.get('theme')
      if (urlTheme === null) {
          // const localStorageTheme = localStorage.getItem('data-theme');
          urlParams.set('theme', this.themeStatus ? 'dark' : 'light')
          window.history.replaceState( {}, import.meta.env.VITE_PRODUCT_NAME, `${window.location.origin}${window.location.pathname}?${urlParams.toString()}` );
      }
  },
  methods: {
    ...mapActions([
      'fetchOrder',
    ]),
    ...mapMutations('app', ['setTheme']),
    updateWidth() {
      this.width = window.innerWidth;
    },
    toggleTheme() {
        const queryString = window.location.search
        const urlParams = new URLSearchParams(queryString);
        const urlTheme = urlParams.get('theme')
        const urlBoolean = urlTheme === 'dark'
      const productName = import.meta.env.VITE_PRODUCT_NAME
        const dark = productName !== 'Apollopayment' ? 'public-dark' : 'apollo-public-dark'
        const light = productName !== 'Apollopayment' ? 'public-light' : 'apollo-public-light'

        if (urlTheme !== null) {
            this.setTheme(urlTheme === 'dark' ? 'light' : 'dark')
            this.$store.commit('changeThemeStatus', urlBoolean);

            if (urlBoolean) document.documentElement.setAttribute("data-theme", dark);
            else document.documentElement.setAttribute("data-theme", light);
            return
        }

      const themeOsDark = window.matchMedia("(prefers-color-scheme: dark)");
      const theme = localStorage.getItem('data-theme');

      this.setTheme(theme === 'dark' ? 'light' : 'dark') //пишем тему в стор

      if (theme === dark) {
        this.$store.commit('changeThemeStatus', true);
        document.documentElement.setAttribute("data-theme", dark);

      } else if (theme === light) {
        this.$store.commit('changeThemeStatus', false);
        document.documentElement.setAttribute("data-theme", light);
      } else if (themeOsDark.matches) {
        this.$store.commit('changeThemeStatus', true);
        document.documentElement.setAttribute("data-theme", dark);
      } else {
        this.$store.commit('changeThemeStatus', false);
        document.documentElement.setAttribute("data-theme", light);
      }
    },
  },
}
</script>

<style lang="scss">
  @import url("https://fonts.googleapis.com/css2?family=Roboto:wght@400;500;600;700&display=swap");
  .main-body {
    background: var(--body-color);
  }
  .checkout-main-page {
    position: relative;
    height: 100vh;
    font-family: 'Gilroy';
  }
</style>
