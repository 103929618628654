<template>
  <div class="you-can-pay-wrapper">
    <svg xmlns="http://www.w3.org/2000/svg" width="15" height="15" viewBox="0 0 15 15" fill="none">
      <path
        d="M13.75 12V12.75C13.75 13.575 13.075 14.25 12.25 14.25H1.75C0.9175 14.25 0.25 13.575 0.25 12.75V2.25C0.25 1.425 0.9175 0.75 1.75 0.75H12.25C13.075 0.75 13.75 1.425 13.75 2.25V3H7C6.1675 3 5.5 3.675 5.5 4.5V10.5C5.5 11.325 6.1675 12 7 12H13.75ZM7 10.5H14.5V4.5H7V10.5ZM10 8.625C9.3775 8.625 8.875 8.1225 8.875 7.5C8.875 6.8775 9.3775 6.375 10 6.375C10.6225 6.375 11.125 6.8775 11.125 7.5C11.125 8.1225 10.6225 8.625 10 8.625Z"
        fill="var(--text-text-primary-accent)"
      />
    </svg>
    <div class="text">
      {{ $t('new_checkout.you_can_pay') }}
    </div>
  </div>
</template>

<script>
export default {
  name: 'YouCanPay'
}
</script>

<style scoped lang="scss">
  .you-can-pay-wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 8px;

    padding: 15px 12px 12px;

    background-color: var(--bg-background-disabled);

    .text {
      font-family: "Gilroy";

      text-align: center;
      color: var(--text-text-primary);
      font-weight: 600;
      font-size: 13px;
    }
  }
</style>